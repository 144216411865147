<template>
  <el-dialog top="1vh" :title="title" width="1050px" :visible.sync="is_show_in_page">
    <el-form class="form_ordinary" label-width="165px">
      <div class="tab1_inner">
        <el-form-item class="el_form_item" label="支付流水号" style="width: 450px;margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.ra_num"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="内部运单编号" style="width:450px; margin: 10px 0px" >
          <el-input class="el_inner_width" v-model="form.truck_tord_num" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="外部运单编号" style="width:450px; margin: 10px 0px" >
             <el-input class="el_inner_width" v-model="form.out_trade_num " ></el-input>
      </el-form-item>
      <el-form-item class="el_form_item" label="金额" style="width:450px; margin: 10px 0px" >
           <el-input class="el_inner_width" v-model="form.much " ></el-input>
      </el-form-item>
        <!-- <el-form-item class="el_form_item" label="充值流水号" style="width: 450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.sequence_code" ></el-input>
        </el-form-item>
      </div> -->
        <el-form-item class="el_form_item" label="运单类型" style="width:450px; margin: 10px 0px" >
          <el-select class="el_input" v-model="form.tord_type" clearable style="width:268px">
          <el-option label="实时单" value="1"></el-option>
          <el-option label="后录单" value="2"></el-option>
        </el-select>
        </el-form-item>
        <el-form-item class="el_form_item" label="状态"  style="width:450px; margin: 10px 0px">
        <el-select class="el_input" v-model="form.status" clearable style="width:268px">
          <el-option label="未处理" value="1"></el-option>
          <el-option label="处理中" value="2"></el-option>
          <el-option label="已处理" value="3"></el-option>
        </el-select>

        </el-form-item>
        <el-form-item class="el_form_item" label="备注" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.mark" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="银行卡号" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.bank_card_num" type="number"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="姓名" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.name" ></el-input>
        </el-form-item>
         <el-form-item class="el_form_item" label="新的收款银行卡号" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.new_card_num" type="number"></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="新的收款人姓名" style="width:450px; margin: 10px 0px">
          <el-input class="el_inner_width" v-model="form.new_name" ></el-input>
        </el-form-item>
        <el-form-item class="el_form_item" label="处理时间" style="width:480px; margin: 10px 0px">
             <el-date-picker style="width:268px;" v-model="set_time " type="datetime" placeholder="选择处理时间"></el-date-picker>
        </el-form-item>
       <el-form-item class="el_form_item" label="发生时间" style="width:450px; margin: 10px 0px">
             <el-date-picker style="width:268px;" v-model="pay_time " type="datetime" placeholder="选择发生时间"></el-date-picker>
        </el-form-item>
      </div>
        <div class="btn_area">
			<el-button type="primary" @click="add">{{title}}</el-button>
		</div>
    </el-form>
  </el-dialog>
</template>
<script>
import { Loading } from "element-ui";
export default {
  props: {
    is_show: Number, //是否显示
    data:Object,
    title:String,
    type:Number,
  },
  data() {
    return {
      //页面是否显示
      is_show_in_page: false,
      balance_type:'',
      id:'',
      form:{
        ra_num:'',// 支付流水号
        truck_tord_num:'',// 内部运单编号
        out_trade_num:'',// 外部运单编号
        much:'',// 金额
        tord_type:'',// 运单类型 (1 real_time 实时单 2 after_time 后录单)
        status :'',//状态(1,未处理 2,处理中 3已处理)
        mark:'',// 备注
        bank_card_num:'',// 银行卡号
        name:'',// 姓名
        new_card_num:'',// 新的收款银行卡号
        new_name:'',// 新的收款人姓名
      },
        set_time:'',// 处理时间
        pay_time:'',// 发生时间
    };
  },
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.is_show_in_page = true;
      }
    },
    data(new_data) {
      if(new_data){
        this.id = new_data.id
        this.form={
            ra_num:new_data.ra_num,// 支付流水号
            truck_tord_num:new_data.truck_tord_num,// 内部运单编号
            out_trade_num:new_data.out_trade_num,// 外部运单编号
            much:new_data.much,// 金额
            tord_type:new_data.tord_type,// 运单类型 (1 real_time 实时单 2 after_time 后录单)
            status :new_data.status,//状态(1,未处理 2,处理中 3已处理)
            mark:new_data.mark,// 备注
            bank_card_num:new_data.bank_card_num,// 银行卡号
            name:new_data.name,// 姓名
            new_card_num:new_data.new_card_num,// 新的收款银行卡号
            new_name:new_data.new_name,// 新的收款人姓名
        }
        if(new_data.pay_time!=0){
            this.pay_time = this.$my.other.totime(new_data.pay_time);
        }else{
            this.pay_time = ''
        }
        if(new_data.set_time !=0){
            this.set_time = this.$my.other.totime(new_data.set_time);
        }else{
            this.set_time = ''
        }
      }
    },
  },
  methods: {
      //保存
      add(){
          var set_time = new Date(this.set_time).getTime() /1000
          var pay_time = new Date(this.pay_time).getTime() /1000
          console.log(set_time);
          if(!set_time){
              this.$my.net.req({
                data:{
                    mod:'finance',
                    ctr:'out_cash_failed_set',
                    set_type:this.type,
                    pay_time,
                    id:this.id,
                    ...this.form
                },
                callback:(data)=>{
                this.$my.other.msg({
                  type:'success',
                  str:"保存成功"
                  });
                  this.is_show_in_page = false
                  this.$emit('init')
                }
            })
            return
          }
          this.$my.net.req({
              data:{
                  mod:'finance',
                  ctr:'out_cash_failed_set',
                  set_type:this.type,
                  set_time,
                  pay_time,
                  id:this.id,
                  ...this.form
              },
              callback:(data)=>{
              this.$my.other.msg({
                 type:'success',
                 str:"保存成功"
                });
                this.is_show_in_page = false
                this.$emit('init')
              }
          })
      }
    },
};
</script>
<style lang="scss" scoped>
.el_form_item {
  margin: 0;
  .right{
    margin-right: 20px;
  }
  .bottom_text {
    margin-top: 5px;
    margin-bottom: 20px;
    text-align: center;
  }
}
.big_tit {
  background-color: #eee;
  padding: 10px 20px;
  color: #666;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
}
.tab1_inner {
  padding: 15px 0px 0 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.el_inner_width {
  width: 94%;
}

.bottom_btns {
  text-align: center;
  margin-top: 30px;
}

.form_ordinary {
  // height: calc(100% - 91px);
  padding: 10px 0;
  overflow-y: auto;
}
  .btn_area {
    text-align: center;
    margin-top: 15px;
  }
</style>
<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="90px">
			<el-form-item class="el_form_item" label="流水编号">
				<el-input class="el_input" v-model="form.ra_num" placeholder="流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="上游编号">
				<el-input class="el_input" v-model="form.out_trade_num" placeholder="上游编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="发生时间">
				<div class="block">
					<el-date-picker
						v-model="pay_time"
						type="datetimerange"
						:picker-options="pickerOptions"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right"
						value-format="timestamp"
					>
					</el-date-picker>
					</div>
			</el-form-item>
			<el-form-item class="el_form_item" >
				
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="cargo_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="primary" @click="add_error_pay('添加',1)">添加异常打款</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="支付编号/运单编号/上游编号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">支付: {{scope.row.ra_num}}</div> 
						<div class="tab_line_item">运单: {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">上游: {{scope.row.out_trade_num}}</div>
						<div class="tab_line_item" v-if="scope.row.pay_time!=0">发生时间: {{scope.row.pay_time_text}}</div>
						<div class="tab_line_item" v-if="scope.row.set_time!=0">处理时间: {{scope.row.set_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="金额" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item"> {{scope.row.much}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="旧卡号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item"> {{scope.row.bank_card_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="旧姓名" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item"> {{scope.row.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="新卡号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item"> {{scope.row.new_card_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="新姓名" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item"> {{scope.row.new_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="备注" >
					<template slot-scope="scope">
						<div class="tab_line_item" v-if="scope.row.mark"> {{scope.row.mark}}</div>
						<div class="tab_line_item" v-if="scope.row.marks" v-for="item in scope.row.marks"> {{item}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="100px"> 
					<template slot-scope="scope">
					<div>
					<el-button  size="mini" type="text" @click="add_error_pay('编辑',2,scope.row)" style="margin-left: 20px">编辑</el-button>
					<!-- <el-button @click="waybill_input" size="mini" type="text">对应运单</el-button> -->
					</div>
				</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<addEerror 
		:is_show="edit.is_show"
		:data="edit.data"
		@init="get_page_data()"
		:title="edit.title"
		:type="edit.type"
	></addEerror>
	</div>
</template>

<script>
import addEerror from './add_error.vue'
	export default {
		components:{
			addEerror,
		},
		data() {
			return {

				//搜索条件
				form: {				
					ra_num:'',//流水编号
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					pay_time_start:'',//发生开始时间
					pay_time_end:'',//发生结束时间
				},
				pay_time:[],
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},
				edit:{
					is_show:0,
					data:{},
					title:'',
					type:0,
				}
			}
		},
		created() {
			
			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}
			
			//读取货源
			this.get_page_data()
		},
		methods: {
			//添加异常打款功能
			add_error_pay(title,type,data){
				this.edit.is_show ++
				this.edit.title = title
				this.edit.type = type
				this.edit.data = data
			},
			//清空查询条件
			ser_para_clear(){
				this.form={
					ra_num:'',//流水编号
					truck_tord_num:'',//货源编号
					out_trade_num:'',//上游编号
					pay_time_start:'',//发生开始时间
					pay_time_end:'',//发生结束时间
				}
				this.get_page_data()
			},

			//搜索
			cargo_ser(){
			if(this.pay_time){
				this.form.pay_time_start = parseInt(this.pay_time[0] / 1000);
				this.form.pay_time_end = parseInt(this.pay_time[1] / 1000);
				this.get_page_data()
			}else{
				this.form.pay_time_start = ''
				this.form.pay_time_start = ''
				this.get_page_data()
			}
		},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}
				
				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:"finance",
						ctr:"out_cash_failed_list",
						// mod:'truck_tord_real_time',
						// ctr:'tord_payed_list_by_admin',
						// is_get_shipper_info:true,
						// is_get_truck_owner_info:true,
						// is_get_driver_info:true,
						// is_get_tord_info:true,
						...this.form,
						...this.page
					},
					callback:(data)=>{
						//加载完毕
						this.list.loading=false
						
						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){
							item.set_time_text=this.$my.other.totime(item.set_time);
							item.pay_time_text=this.$my.other.totime(item.pay_time);
						}

						//渲染
						this.list.data=data.list
					}
				})
				this.list.loading=false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 134px);
	}
	.el_form_item{
		width: 275px;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>